import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Table, {
  AvatarCell,
  // SelectColumnFilter,
  // StatusPill
} from "@shared/component/table/table";
import Modal from "@shared/component/modal/modal";
import DeletePopup from "@shared/component/delete-popup";
import UserAvatar from "assets/image/profile.png";
import { HandThumbUpIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import Toggle from "@shared/component/toggle";
import Tabs from "@shared/component/tabs/tabs";
import { useNavigate } from "react-router-dom";
const infoIcon = require("../../../assets/image/icons/info.svg");

const CommentSearchFileds = ({ commentQueryData, setCommentQueryData }) => {
  const { t } = useTranslation();
  const [searchData, setSearchData] = useState({
    userName: "",
    value: "",
  });

  return (
    <>
      <div className="flex items-center gap-6 mb-5">
        <div className="flex items-center flex-1 gap-6">
          <div className="flex-item flex-1">
            <input
              type="text"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full max-w-full focus:outline-none focus:ring-primary focus:border-primary"
              placeholder="User Name"
              name="userName"
              value={searchData.userName}
              onChange={(e) =>
                setSearchData({ ...searchData, userName: e.target.value })
              }
              autoComplete="off"
            />
          </div>

          <div className="flex-item flex-1">
            <input
              type="text"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full max-w-full focus:outline-none focus:ring-primary focus:border-primary"
              placeholder="Comment"
              name="comment"
              value={searchData.value}
              onChange={(e) =>
                setSearchData({ ...searchData, value: e.target.value })
              }
              autoComplete="off"
            />
          </div>
        </div>
        <div className="shrink-0 flex gap-4">
          <button
            className={`inline-flex justify-center float-right rounded-md border-transparent bg-gradient-primary px-10 py-2 text-lg text-white focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed`}
            onClick={() => {
              setCommentQueryData({
                ...commentQueryData,
                ...searchData,
                isFilter: true,
                isSearched: true,
              });
            }}
          >
            Search
          </button>
          <button
            className={`inline-flex justify-center float-right rounded-md border-transparent bg-gradient-primary px-10 py-2 text-lg text-white focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed`}
            onClick={() => {
              const tempData = { userName: "", value: "" };
              setSearchData({
                userName: "",
                value: "",
              });
              setCommentQueryData({
                ...commentQueryData,
                ...tempData,
                isFilter: true,
                isSearched: true,
              });
            }}
          >
            Clear
          </button>
        </div>
      </div>
    </>
  );
};

const RecipeComment = ({
  commentListData,
  isCommentListEmpty,
  commentQueryData,
  setCommentQueryData,
  deleteComment,
  updateComment,
}) => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        Header: t(`COMMON.SNO`),
        accessor: "sno",
        disableSortBy: true,
      },
      {
        Header: t(`COMMON.NAME_USERS`),
        accessor: "user",
        Cell: AvatarCell,
        imgAccessor: "imgUrl",
        AvatarCellLink: "link",
      },
      {
        Header: t(`COMMON.COMMENTS`),
        accessor: "comments",
      },
      {
        Header: t(`COMMON.LIKES`),
        accessor: "likes",
      },
      {
        Header: t(`COMMON.ACTION`),
        accessor: "action",
        disableSortBy: true,
      },
    ],
    [t]
  );

  const getData = () => {
    const snoBase = commentListData?.page?.number * commentListData?.page?.size;
    const commnetList = commentListData?.content?.map(function (
      commnet,
      index
    ) {
      const sno = snoBase + index + 1;
      // const recipeState =
      //   recipe?.state?.toLowerCase() === "active" ? true : false;
      return {
        sno: sno,
        user:
          commnet?.user?.name &&
          Object?.values(commnet?.user?.name)?.join(" ")?.trim(),
        comments:
          commnet?.comment?.substring(0, 30) +
            (commnet?.comment?.length > 30 ? "..." : "") || "",
        likes: commnet?.likes || 0,
        imgUrl: commnet?.user?.profileImage?.url || UserAvatar,
        link: "/user-profile/" + commnet?.user?.id,
        action: (
          <>
            <div className="flex">
              <Toggle
                checked={!commnet?.isBlocked}
                onClickConfirm={(e) => {
                  updateComment(commnet);
                }}
              />
              <DeletePopup
                className="ml-2"
                onClickConfirm={() => {
                  deleteComment(commnet?.id);
                }}
              />
            </div>
          </>
        ),
      };
    });
    return commnetList;
  };

  const handleSorting = React.useCallback((sortData) => {
    let sort = "desc";
    if (sortData?.length > 0) {
      const sortKeyId = sortData?.[0]?.id;
      sort = sortData?.[0]?.desc ? "desc" : "asc";
      let sortKey = "";
      switch (sortKeyId) {
        case "user": {
          sortKey = "comment.userName";
          break;
        }
        case "comments": {
          sortKey = "comment.comment";
          break;
        }
        case "likes": {
          sortKey = "comment.likeCount";
          break;
        }
        default: {
          sortKey = "";
        }
      }

      if (sortKey) {
        sort = `${sortKey},${sort}`;
      }

      setCommentQueryData({
        ...commentQueryData,
        sort: sort,
        isFilter: true,
      });
    } else {
      setCommentQueryData({
        ...commentQueryData,
        sort: sort,
        isFilter: true,
      });
    }
  }, []);

  return (
    <>
      <div className="grow bg-gray-100 text-gray-900">
        <div className="h-full mx-auto">
          <div className="flex flex-col h-full">
            <>
              <Table
                columns={columns}
                data={getData() || []}
                manualPagination={true}
                tablePageData={commentListData?.page}
                queryData={commentQueryData}
                setQueryData={setCommentQueryData}
                isNoData={isCommentListEmpty && !commentQueryData?.isSearched}
                noDataText={t(`COMMON.NO_COMMENTS_FOUND`)}
                disableSearch={true}
                SearchFields={
                  <CommentSearchFileds
                    commentQueryData={commentQueryData}
                    setCommentQueryData={setCommentQueryData}
                  />
                }
                onSortChange={(sortData) => handleSorting(sortData)}
              />
            </>
          </div>
        </div>
      </div>
    </>
  );
};

const SubstitutionSearchFields = ({ subsQueryData, setSubsQueryData }) => {
  const { t } = useTranslation();
  const [searchData, setSearchData] = useState({
    userName: "",
    value: "",
  });
  return (
    <>
      <div className="flex items-center gap-6 mb-5">
        <div className="flex flex-wrap items-center flex-1 gap-6">
          <div className="flex-item flex-1">
            <input
              type="text"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full max-w-full focus:outline-none focus:ring-primary focus:border-primary"
              placeholder="User Name"
              name="userName"
              value={searchData?.user}
              onChange={(e) =>
                setSearchData({ ...searchData, userName: e.target.value })
              }
              autoComplete="off"
            />
          </div>
          <div className="flex-item flex-1">
            <input
              type="text"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full max-w-full focus:outline-none focus:ring-primary focus:border-primary"
              placeholder="Substitution"
              name="substitution"
              value={searchData?.value}
              onChange={(e) =>
                setSearchData({ ...searchData, value: e.target.value })
              }
              autoComplete="off"
            />
          </div>
        </div>
        <div className="shrink-0 flex gap-4">
          <button
            className={`inline-flex justify-center float-right rounded-md border-transparent bg-gradient-primary px-10 py-2 text-lg text-white focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed`}
            onClick={() => {
              setSubsQueryData({
                ...subsQueryData,
                ...searchData,
                isFilter: true,
                isSearched: true,
              });
            }}
          >
            Search
          </button>
          <button
            className={`inline-flex justify-center float-right rounded-md border-transparent bg-gradient-primary px-10 py-2 text-lg text-white focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed`}
            onClick={() => {
              const tempData = { userName: "", value: "" };
              setSearchData({
                userName: "",
                value: "",
              });
              setSubsQueryData({
                ...subsQueryData,
                ...tempData,
                isFilter: true,
                isSearched: true,
              });
            }}
          >
            Clear
          </button>
        </div>
      </div>
    </>
  );
};

const RecipeSubstitution = ({
  substitutionData,
  issubstitutionEmpty,
  subsQueryData,
  setSubsQueryData,
  deleteSubstitution,
  updateSubstitution,
}) => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        Header: t(`COMMON.SNO`),
        accessor: "sno",
        disableSortBy: true,
      },
      {
        Header: t(`COMMON.NAME_USERS`),
        accessor: "user",
        Cell: AvatarCell,
        imgAccessor: "imgUrl",
        AvatarCellLink: "link",
      },
      {
        Header: t(`COMMON.SUBSTITUTION`),
        accessor: "substitution",
      },
      {
        Header: t(`COMMON.LIKES`),
        accessor: "likes",
      },
      {
        Header: t(`COMMON.ACTION`),
        accessor: "action",
        disableSortBy: true,
      },
    ],
    [t]
  );

  const getData = () => {
    const snoBase =
      substitutionData?.page?.number * substitutionData?.page?.size;
    const substitutionList = substitutionData?.content?.map(function (
      substitution,
      index
    ) {
      console.log(substitution);
      const sno = snoBase + index + 1;
      // const recipeState =
      //   recipe?.state?.toLowerCase() === "active" ? true : false;
      return {
        sno: sno,
        user:
          substitution?.user?.name &&
          Object?.values(substitution?.user?.name)?.join(" ")?.trim(),
        substitution:
          substitution?.substitution?.substring(0, 30) +
            (substitution?.substitution?.length > 30 ? "..." : "") || "",
        likes: substitution?.likes || 0,
        imgUrl: substitution?.user?.profileImage?.url || UserAvatar,
        link: "/user-profile/" + substitution?.user?.id,
        action: (
          <>
            <div className="flex">
              <Toggle
                checked={!substitution?.isBlocked}
                onClickConfirm={(e) => {
                  updateSubstitution(substitution);
                }}
              />
              <DeletePopup
                className="ml-2"
                onClickConfirm={() => {
                  deleteSubstitution(substitution?.id);
                }}
              />
            </div>
          </>
        ),
      };
    });
    return substitutionList;
  };

  const handleSorting = React.useCallback((sortData) => {
    let sort = "desc";
    if (sortData?.length > 0) {
      const sortKeyId = sortData?.[0]?.id;
      sort = sortData?.[0]?.desc ? "desc" : "asc";
      let sortKey = "";
      switch (sortKeyId) {
        case "user": {
          sortKey = "substitution.userName";
          break;
        }
        case "substitution": {
          sortKey = "substitution.substitution";
          break;
        }
        case "likes": {
          sortKey = "substitution.likeCount";
          break;
        }
        default: {
          sortKey = "";
        }
      }

      if (sortKey) {
        sort = `${sortKey},${sort}`;
      }

      setSubsQueryData({
        ...subsQueryData,
        sort: sort,
        isFilter: true,
      });
    } else {
      setSubsQueryData({
        ...subsQueryData,
        sort: sort,
        isFilter: true,
      });
    }
  }, []);

  return (
    <>
      <div className="grow bg-gray-100 text-gray-900">
        <div className="h-full mx-auto">
          <div className="flex flex-col h-full">
            <>
              <Table
                columns={columns}
                data={getData() || []}
                manualPagination={true}
                tablePageData={substitutionData?.page}
                queryData={subsQueryData}
                setQueryData={setSubsQueryData}
                isNoData={issubstitutionEmpty && !subsQueryData?.isSearched}
                noDataText={t(`COMMON.NO_SUBSTITUTIONS_FOUND`)}
                disableSearch={true}
                SearchFields={
                  <SubstitutionSearchFields
                    subsQueryData={subsQueryData}
                    setSubsQueryData={setSubsQueryData}
                  />
                }
                onSortChange={(sortData) => handleSorting(sortData)}
              />
            </>
          </div>
        </div>
      </div>
    </>
  );
};

const RecipeInfoPresentational = ({
  recipeData,
  commentListData,
  substitutionData,
  isCommentListEmpty,
  issubstitutionEmpty,
  commentQueryData,
  setCommentQueryData,
  subsQueryData,
  setSubsQueryData,
  deleteComment,
  deleteSubstitution,
  updateSubstitution,
  updateComment,
  tabIndex,
  setTabIndex,
  isDescriptionModelOpen,
  setIsDescriptionModelOpen,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="relative">
      <img
        className="absolute top-0 left-0 cursor-pointer"
        src={require("../../../assets/image/icons/back-btn.svg").default}
        alt="back-btn"
        onClick={() => navigate(-1)}
      />
      <div className="flex gap-8 mx-20 p-6 bg-white">
        <div className="flex flex-wrap gap-6 items-center">
          <div className="shrink-0">
            {recipeData?.galleries?.length ? (
              <img
                className="rounded-full w-32 h-32"
                src={recipeData?.galleries[0]?.url}
                alt="receipe"
              />
            ) : (
              <img
                className="rounded-full w-32 h-32"
                src={UserAvatar}
                alt="receipe"
              />
            )}
          </div>
        </div>
        <div className="flex flex-1 flex-col">
          <div className="flex flex-row items-center">
            <div className="flex flex-col">
              <div className="flex items-center gap-1">
                <img
                  src={infoIcon?.default}
                  className="h-[10px] min-h-[10px] w-[10px] min-w-[10px]"
                  alt="info"
                />
                <span className="text-[#9E9E9E] text-[10px] inter-400">
                  Recipe
                </span>
              </div>
              <span className="text-[32px] font-medium text-gray-700">
                {recipeData?.name}
              </span>
            </div>
            <div className="flex items-center">
              <div className="px-4">
                <div className="text-white bg-gradient-primary rounded-md px-3.5 py-2.5 flex gap-6 items-center">
                  {/* <UserGroupIcon className="w-8"/> */}
                  <div className="w-8 shrink-0">
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M33.7149 0H4.08086C3.10077 0.00394935 2.16196 0.395038 1.46893 1.08807C0.775897 1.7811 0.384809 2.71991 0.380859 3.7V37.038L7.78886 29.63H33.7149C34.6949 29.6261 35.6338 29.235 36.3268 28.5419C37.0198 27.8489 37.4109 26.9101 37.4149 25.93V3.7C37.4109 2.71991 37.0198 1.7811 36.3268 1.08807C35.6338 0.395038 34.6949 0.00394935 33.7149 0Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>
                    <p className="text-xs inter-400">{t(`COMMON.COMMENT`)}</p>
                    <h3 className="text-3xl">
                      {recipeData?.commentCount || 0}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="px-4">
                <div className="text-white bg-gradient-primary rounded-md px-3.5 py-2.5 flex gap-6 items-center">
                  {/* <HandThumbUpIcon className="w-8" /> */}
                  <div className="w-8 shrink-0">
                    <svg
                      width="39"
                      height="37"
                      viewBox="0 0 39 37"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.2531 1.28697L12.6621 10.855L2.38905 12.395C1.96854 12.4685 1.57631 12.6562 1.25514 12.9374C0.933964 13.2186 0.696185 13.5826 0.567737 13.9897C0.439289 14.3968 0.425106 14.8314 0.526735 15.246C0.628363 15.6607 0.8419 16.0394 1.14405 16.341L8.57605 23.784L6.82105 34.301C6.74096 34.7181 6.77992 35.1493 6.93348 35.5453C7.08704 35.9413 7.349 36.2861 7.68936 36.5402C8.02973 36.7943 8.43475 36.9474 8.85808 36.982C9.2814 37.0166 9.70593 36.9314 10.0831 36.736L19.2731 31.771L28.4631 36.736C28.8401 36.9294 29.2639 37.0131 29.6862 36.9776C30.1085 36.9421 30.5124 36.7888 30.8518 36.5351C31.1913 36.2813 31.4527 35.9375 31.6064 35.5425C31.7601 35.1475 31.7998 34.7174 31.7211 34.301L29.9661 23.784L37.3981 16.341C37.7002 16.0394 37.9137 15.6607 38.0154 15.246C38.117 14.8314 38.1028 14.3968 37.9744 13.9897C37.8459 13.5826 37.6081 13.2186 37.287 12.9374C36.9658 12.6562 36.5736 12.4685 36.1531 12.395L25.8801 10.855L21.2891 1.28697C21.1104 0.902296 20.8255 0.576697 20.468 0.348533C20.1105 0.120369 19.6952 -0.000854492 19.2711 -0.000854492C18.8469 -0.000854492 18.4316 0.120369 18.0741 0.348533C17.7166 0.576697 17.4317 0.902296 17.2531 1.28697Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>
                    <p className="text-xs inter-400">{t(`COMMON.RATING`)}s</p>
                    <h3 className="text-3xl">
                      {Number(recipeData?.avgRating || 0).toFixed(1)}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="px-4">
                <div className="text-white bg-gradient-primary rounded-md px-3.5 py-2.5 flex gap-6 items-center">
                  {/* <UserGroupIcon className="w-8"/> */}
                  <div className="w-8 shrink-0">
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M33.7149 0H4.08086C3.10077 0.00394935 2.16196 0.395038 1.46893 1.08807C0.775897 1.7811 0.384809 2.71991 0.380859 3.7V37.038L7.78886 29.63H33.7149C34.6949 29.6261 35.6338 29.235 36.3268 28.5419C37.0198 27.8489 37.4109 26.9101 37.4149 25.93V3.7C37.4109 2.71991 37.0198 1.7811 36.3268 1.08807C35.6338 0.395038 34.6949 0.00394935 33.7149 0Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>
                    <p className="text-xs inter-400">Substitution</p>
                    <h3 className="text-3xl">
                      {recipeData?.substitutionCount || 0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {recipeData?.description && (
            <div className="mt-2">
              <button
                className={
                  "text-white bg-primary mt-2 hover:bg-secondary font-medium rounded-lg inter-400 text-sm px-10 py-2.5 mr-2 mb-2 focus:outline-none"
                }
                onClick={() => setIsDescriptionModelOpen(true)}
              >
                {t(`COMMON.RECIPE_INFO`)}
              </button>
              <Modal
                isOpen={isDescriptionModelOpen}
                onClose={() => setIsDescriptionModelOpen(false)}
                modalTitleText={t(`COMMON.RECIPE_INFO`)}
                modalContent={recipeData?.description}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mx-20">
        <Tabs
          selectedIndex={tabIndex}
          setSelectedIndex={(index) => setTabIndex(index)}
          tabsData={[
            {
              tabTitle: "Recipe Comments",
              tabContent: (
                <RecipeComment
                  commentListData={commentListData}
                  isCommentListEmpty={isCommentListEmpty}
                  commentQueryData={commentQueryData}
                  setCommentQueryData={setCommentQueryData}
                  deleteComment={deleteComment}
                  updateComment={updateComment}
                />
              ),
            },
            {
              tabTitle: "Recipe Substitution",
              tabContent: (
                <RecipeSubstitution
                  substitutionData={substitutionData}
                  issubstitutionEmpty={issubstitutionEmpty}
                  subsQueryData={subsQueryData}
                  setSubsQueryData={setSubsQueryData}
                  deleteSubstitution={deleteSubstitution}
                  updateSubstitution={updateSubstitution}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default RecipeInfoPresentational;
