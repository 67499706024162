import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  // SearchIcon,
  ArrowRightOnRectangleIcon,
  UserIcon,
  // AdjustmentsVerticalIcon,
  // BellIcon,
} from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BellIcon, ChevronDownIcon } from "@heroicons/react/24/solid";

import AuthService from "service/auth-service";
import UserAvatar from "assets/image/profile.png";

const NavBar = () => {
  const { t } = useTranslation();

  const navBarHeight = 71;

  const HeaderData = useSelector((state) => state?.HeaderReducer);

  const CurrentUser = AuthService?.getCurrentUser();
  const Name =
    CurrentUser?.name && Object?.values(CurrentUser?.name)?.join(" ")?.trim();
  const ProfileImageURL = CurrentUser?.profileImage?.url || UserAvatar;

  return (
    <nav
      className="flex items-center space-x-4 shadow bg-white w-full px-5 absolute z-10"
      style={{ height: navBarHeight }}
    >
      <div className="hidden md:inline-flex flex-1">{HeaderData?.pageName}</div>

      <div className="flex-1 md:flex-none"></div>

      <a href="!#" className="relative text-gray-500 hover:text-gray-800">
        <span className="absolute top-[-10px] right-[-10px] w-6 h-6 bg-red-600 rounded-full"></span>
        <span className="absolute top-[-10px] right-[-10px] flex h-6 w-6">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
          <div className="relative rounded-full h-6 w-6 bg-red-600 flex items-center justify-center">
            <span className="text-sm text-white">
              {HeaderData?.notificationCount >= 100
                ? "99+"
                : HeaderData?.notificationCount}
            </span>
          </div>
        </span>
        <BellIcon className="w-8 h-8" />
      </a>

      <Menu
        as="div"
        className="relative inline-block text-left border-l-2 border-gray-300 pl-3"
      >
        <div>
          <Menu.Button className="inline-flex justify-center w-full items-center text-gray-500 hover:text-gray-800 focus:outline-none">
            <img
              className="rounded-full w-8 h-8"
              src={ProfileImageURL}
              alt={`${Name}-Avatar`}
            />
            <span className="font-medium ml-3 mr-1">{Name}</span>
            <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={"/my-profile"}
                    className={`${
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    <UserIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                    {t(`COMMON.MY_PROFILE`)}
                  </Link>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => {
                      AuthService?.logout();
                    }}
                  >
                    <ArrowRightOnRectangleIcon
                      className="w-5 h-5 mr-2"
                      aria-hidden="true"
                    />
                    {t(`COMMON.SIGN_OUT`)}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </nav>
  );
};

export default NavBar;
