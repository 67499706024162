import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Table, {
  AvatarCell,
  // SelectColumnFilter,
  // StatusPill
} from "@shared/component/table/table"; // new
import Toggle from "@shared/component/toggle";
import DeletePopup from "@shared/component/delete-popup";
import UserAvatar from "assets/image/profile.png";

const SearchFields = ({ userQueryData, setUserQueryData }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [recipeCount, setRecipeCount] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("");

  return (
    <>
      <div className="flex items-center gap-6 mb-5">
        <div className="flex flex-wrap items-center flex-1 gap-6">
          <div className="flex-item flex-1">
            <input
              type="text"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full focus:outline-none focus:ring-primary focus:border-primary"
              placeholder="Name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div className="flex-item flex-1">
            <input
              type="text"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full focus:outline-none focus:ring-primary focus:border-primary"
              placeholder="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div className="flex-item flex-1">
            <input
              type="number"
              min="0"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full focus:outline-none focus:ring-primary focus:border-primary"
              placeholder="Recipe Count"
              name="recipeCount"
              value={recipeCount}
              onChange={(e) => setRecipeCount(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div className="flex-item flex-1">
            <select
              name="subscriptionStatus"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full focus:outline-none focus:ring-primary focus:border-primary"
              value={subscriptionStatus}
              onChange={(e) => setSubscriptionStatus(e.target.value)}
            >
              <option value="">{t(`COMMON.SUBSCRIPTION_STATUS`)}</option>
              <option value="PAID">{t(`COMMON.PAID`)}</option>
              <option value="UNPAID">{t(`COMMON.UNPAID`)}</option>
            </select>
          </div>
        </div>
        <div className="shrink-0">
          <button
            className={`inline-flex justify-center float-right rounded-md border-transparent bg-gradient-primary px-10 py-2 text-lg text-white focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed`}
            onClick={() => {
              setUserQueryData({
                ...userQueryData,
                name: name || null,
                email: email || null,
                recipeCount: recipeCount || null,
                subscriptionType: subscriptionStatus || null,
                page: 0,
                isFilter: true,
                isSearched: true,
              });
            }}
          >
            {t(`COMMON.SEARCH`)}
          </button>
        </div>
        <div className="shrink-0">
          <button
            className={`inline-flex justify-center float-right rounded-md border-transparent bg-gradient-primary px-10 py-2 text-lg text-white focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed`}
            onClick={() => {
              setUserQueryData({
                search: "",
                page: 0,
                size: 10,
                roleIds: 3,
                sort: "desc",
                roles: "USER",
                isFilter: true,
                isSearched: false,
              });
              setName("");
              setEmail("");
              setRecipeCount("");
              setSubscriptionStatus();
            }}
          >
            {t(`COMMON.CLEAR`)}
          </button>
        </div>
      </div>
    </>
  );
};

const UserManagementPresentational = ({
  isUserListEmpty,
  userQueryData,
  setUserQueryData,
  userListData,
  updateUserStatus,
  deleteUser,
  searchUsers,
}) => {
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        Header: t(`COMMON.SNO`),
        accessor: "sno",
        disableSortBy: true,
      },
      {
        Header: t(`COMMON.NAME_USERS`),
        accessor: "name",
        Cell: AvatarCell,
        imgAccessor: "imgUrl",
        AvatarCellLink: "link",
        // emailAccessor: "email",
      },
      {
        Header: t(`COMMON.EMAIL`),
        accessor: "email",
      },
      {
        Header: t(`COMMON.RECIPES`),
        accessor: "recipes",
      },
      {
        Header: t(`COMMON.SUBSCRIPTION`),
        accessor: "subcription",
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
      },
    ],
    [t]
  );

  const getData = useCallback(() => {
    const snoBase = userListData?.page?.number * userListData?.page?.size;
    const userList = userListData?.content?.map(function (user, index) {
      const sno = snoBase + index + 1;
      const userState = user?.state?.toLowerCase() === "active" ? true : false;
      return {
        sno: sno,
        name: (
          <span className="text-primary">
            {user?.name && Object?.values(user?.name)?.join(" ")?.trim()}
          </span>
        ),
        email: user?.email,
        phone: user?.phoneNumber
          ? (user?.phCountryCode + " " + user?.phoneNumber)?.trim()
          : "",
        recipes: user?.recipeCount || 0,
        subcription: (
          <span
            className={`text-xs uppercase ${
              user?.subscriptionType?.toLowerCase() === "paid"
                ? "text-green-700"
                : "text-red-600"
            }`}
          >
            {user?.subscriptionType?.toLowerCase() === "paid"
              ? user?.subscriptionType
              : "Unpaid"}
          </span>
        ),
        imgUrl: user?.profileImage?.url || UserAvatar,
        link: "/user-profile/" + user?.id,
        action: (
          <>
            <div className="flex">
              <Toggle
                checked={userState}
                onClickConfirm={(e) => {
                  updateUserStatus(
                    user?.id,
                    userState === true ? "ACTIVE" : "SUSPENDED"
                  );
                }}
              />
              <DeletePopup
                warningTitle="Delete User?"
                className="ml-2"
                onClickConfirm={() => {
                  deleteUser(user?.id);
                }}
              />
            </div>
          </>
        ),
      };
    });
    return userList;
  }, [userListData]);

  const handleSorting = React.useCallback((sortData) => {
    let sort = "desc";
    if (sortData?.length > 0) {
      const sortKeyId = sortData?.[0]?.id;
      sort = sortData?.[0]?.desc ? "desc" : "asc";
      let sortKey = "";
      switch (sortKeyId) {
        case "name": {
          sortKey = "user.firstName";
          break;
        }
        case "email": {
          sortKey = "user.email";
          break;
        }
        case "recipes": {
          sortKey = "user.recipeCount";
          break;
        }
        case "subcription": {
          sortKey = "user.subscriptionType";
          break;
        }
        default: {
          sortKey = "";
        }
      }

      if (sortKey) {
        sort = `${sortKey},${sort}`;
      }

      setUserQueryData({
        ...userQueryData,
        sort: sort,
        isFilter: true,
      });
    } else {
      setUserQueryData({
        ...userQueryData,
        sort: sort,
        isFilter: true,
      });
    }
  }, []);

  return (
    <div className="grow bg-gray-100 text-gray-900">
      <main className="h-full mx-auto">
        <div className="flex flex-col h-full">
          <Table
            columns={columns}
            data={getData() || []}
            manualPagination={true}
            tablePageData={userListData?.page}
            queryData={userQueryData}
            setQueryData={setUserQueryData}
            searchContent={searchUsers}
            isNoData={isUserListEmpty && !userQueryData?.isSearched}
            noDataText={t(`COMMON.NO_USERS_FOUND`)}
            SearchFields={
              <SearchFields
                userQueryData={userQueryData}
                setUserQueryData={setUserQueryData}
              />
            }
            onSortChange={(sortData) => handleSorting(sortData)}
          />
        </div>
      </main>
    </div>
  );
};

export default UserManagementPresentational;
